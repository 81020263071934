import React, { useEffect, useState } from 'react';
import MUIButton from './MUIButton.js';
import MUIMultipleButton from './MUIMultipleButton.js';
import "./background.css";

import psv_logo from './res/PSV_logo_transparent.png';

import { useMsal } from '@azure/msal-react';

import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography, Button } from '@mui/material';

import { ThemeProvider, createTheme  } from '@mui/material/styles';
 

// Define a red theme
const psv_theme = createTheme({
    palette: {
        primary: {
          light: '#f27573',
          main: '#ED1C24',
          dark: '#a73a38',
          contrastText: '#fff',
        },
        secondary: {
          light: '#637bfe',
          main: '#FFFFFF',
          dark: '#2a3eb1',
          contrastText: '#000',
        },
        neutral: {
            light: '#a73a38',
            main: '#424242', 

        }
      },
    typography: {
        "fontFamily": `"psv_font"`,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
    }
});

function Header() {
    return (
        <Box sx = {{ display: 'flex', flexDirection: 'row', margin: 1, alignItems: 'center', justifyContent: 'center'}}>     
            
                <img
                    src={psv_logo}
                    alt="PSV_Logo"
                    style={{width: '10%', height: 'auto', marginRight: 10}}
                />
                
                <Typography variant="h5" color="text.primary"> PSV Pipelines </Typography>

        </Box>
    )
}

function MainContent() {
    const [showMultipleButton, setMultipleButton] = useState(false);
    const [multiButtonText, setMultiButtonText] = useState("Switch naar MultiButton");

    const handleButtonClick = () => {
        setMultipleButton((prevShowMultipleButton) => !prevShowMultipleButton);
        if (showMultipleButton) {
            setMultiButtonText("Switch naar MultiButton");
        } else {
            setMultiButtonText("Switch naar SingleButton");
        }
        
    }

    return (
        <Box display="flex" justifyContent='center' flexDirection="column" alignItems="center" sx = {{width: '100%'}}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '80%'}}> 
                
                    <Button onClick={handleButtonClick} sx={{backgroundColor: 'black', color: 'white'}}> 
                        {multiButtonText}
                    </Button>   
                
            </Box>
        
            <Grid 
                container 
                columnSpacing={4} 
                rowSpacing={8} 
                margin={0} 
                width={'80%'} 
                sx={{borderRadius: 3, backgroundColor: '#D3D3D3', display: showMultipleButton ? 'flex' : 'none'}}
            >
                <Grid item xs={12} md={12}>
                    <MUIMultipleButton 
                        jobId = "430985776685926"
                    />
                </Grid>
            </Grid>

            <Grid 
                container 
                columnSpacing={4} 
                rowSpacing={8} 
                margin={0} 
                width={'80%'} 
                sx={{borderRadius: 3, backgroundColor: '#D3D3D3', display: showMultipleButton ? 'none' : 'flex'}}
            >
                <Grid item xs={12} md={6}>
                    <MUIButton                
                        jobId = "401180678054001"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "254490900710306"
                        containsTableauJob = {true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "1302565844538"
                        containsTableauJob = {true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "1033862208852570"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "409677561948188"
                        containsTableauJob = {true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "122402226371119"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "628634106082803"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "992223890158250"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "1121781943194755"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "1055586197374127"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "595262606805627"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "435006014423036"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MUIButton
                        jobId = "175203580780654"
                    />
                </Grid>
            </Grid>
        </Box>
        
    )
}

function MUIApp() {

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    // Check if a user is already signed in
    if (!accounts.length && inProgress === 'acquireToken') {
        // Authentication in progress
        return;
    }

    // If not signed in, initiate the login process
    if (!accounts.length && inProgress === "none") {
        instance.loginRedirect({
            scopes: ["openid", "profile"]
        });
    }

  }, [instance, accounts, inProgress]);


  if (!accounts.length) {
    return (
      <div>
        <p> U wordt omgeleid naar de authenticatie pagina... </p>
      </div>
    )
  } else {
    return (
    <div class="MUIApp">
      <Box>
        <ThemeProvider theme={psv_theme}>
            <Grid container direction="column" alignItems="center">
                <Grid item xs = {12}>
                    <Header/>
                </Grid>
                <Grid item xs = {12}> 
                    <MainContent/>
                </Grid>
            </Grid>
        </ThemeProvider>
      </Box>
    </div>
    );
  }
}

export default MUIApp;
